<template>
  <div class="flex-1 flex items-center justify-center">
    <div class="w-1/4 shadow-lg">
      <div
          class="mb-1 py-2 px-4 w-full flex items-center rounded-t-lg uppercase bg-gradient-to-r from-primary via-blue-800 to-transparent font-bold tracking-wider text-xl">
        Area Clienti
      </div>

      <div class="w-full mb-1 bg-gray-600 p-6">
        <div class="flex flex-col gap-1">
          <label for="email" class="text-sm font-semibold uppercase tracking-wide">Email</label>
          <input type="email" name="email" id="email" v-model="form.email" class="px-4 py-1 bg-gray-700 text-white rounded-md focus:bg-gray-900"/>
        </div>
        <div class="flex flex-col gap-1 mt-6">
          <label for="password" class="text-sm font-semibold uppercase tracking-wide">Password</label>
          <input type="password" name="password" id="password" v-model="form.password" class="px-4 py-1 bg-gray-700 text-white rounded-md focus:bg-gray-900"/>
        </div>
      </div>

      <div class="w-full flex items-center justify-center bg-gray-900 px-4 py-2 rounded-b-lg">
        <button @click="login" class="px-3 py-1 rounded-full uppercase text-sm border-2 border-white text-white hover:bg-primary hover:border-primary hover:text-black">
          Accesso
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",

  data() {
    return {
      form: {
        email: '',
        password: ''
      },
      password: '1234',
      concessionaire: 'codwin@email.com',
      barOwners: [
        {
          email: 'gioran@email.com',
          name: 'Gioran',
          region: 'Lazio',
          barIndex: 0
        },
        {
          email: 'capanelle@email.com',
          name: 'Capanelle',
          region: 'Lazio',
          barIndex: 1
        },
        {
          email: 'giulia@email.com',
          name: 'Giulia',
          region: 'Lazio',
          barIndex: 2
        },
        {
          email: 'telliera@email.com',
          name: 'Telliera',
          region: 'Veneto',
          barIndex: 0
        },
        {
          email: 'tucano@email.com',
          name: 'Tucano',
          region: 'Veneto',
          barIndex: 1
        },
      ]
    }
  },

  mounted() {
    this.form.email = this.concessionaire;
    this.form.password = this.password;
  },

  methods: {
    login() {
      if(this.form.email === this.concessionaire) {
        this.$root.$data.barSigned = null;
        this.$router.push({name: 'GlimpseManagerBars'})
      } else {
        let bar = this.barOwners.find(barOwner => barOwner.email === this.form.email);

        if(bar) {
          this.$root.$data.barSigned = bar;
          this.$router.push({name: 'GlimpseManagerBar', params: {region: bar.region, bar: bar.barIndex}})
        }
      }
    }
  }
}

</script>

<style scoped>

</style>
