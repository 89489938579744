<template>
  <div class="flex-1" v-if="bar">
    <SectionTitle :title="bar.name" >
      <div class="pb-4 pt-8 px-6 shadow-xl" slot="filters" v-if="!$root.$data.barSigned">
        <div class="inline-flex items-center divide-x divide-white">
          <router-link :to="{name: 'GlimpseManagerBars'}"
                       class="uppercase text-xs font-semibold px-12 hover:text-primary">Dashboard
          </router-link>
        </div>
      </div>
    </SectionTitle>

    <div class="container mx-auto flex justify-between divide-x-2 divide-gray-600 mt-6">
      <div class="flex flex-col items-center justify-center gap-2 pr-10 w-1/4">
        <img class="w-40 h-40 rounded-full" :src="bar.image" :alt="bar.name"/>
        <h1 class="text-white text-2xl uppercase font-semibold my-5">{{ bar.name }} <small
            class="text-sm">({{ $route.params.region }})</small></h1>

        <div class="px-3 py-6 bg-gray-900 shadow-md rounded-md mt-2 w-4/5">
          <p class="text-md w-full flex items-center justify-between"><span
              class="text-secondary uppercase font-semibold">Cabinets: </span>{{ bar.cabinets.length }}</p>
          <p class="text-md w-full flex items-center justify-between"><span
              class="text-secondary uppercase font-semibold">In: </span>{{ bar.in | money }}</p>
          <p class="text-md w-full flex items-center justify-between"><span
              class="text-secondary uppercase font-semibold">Out: </span>{{ bar.out | money }}</p>
          <p class="text-md w-full flex items-center justify-between"><span
              class="text-secondary uppercase font-semibold">% Payout: </span>{{ parseInt(bar.out * 100 / bar.in) }}%</p>
          <p class="text-md w-full flex items-center justify-between"><span
              class="text-primary uppercase font-semibold">Cassetto: </span>{{ bar.in - bar.out | money }}</p>
          <p class="text-md w-full flex items-center justify-between"><span
              class="text-secondary uppercase font-semibold">% Esercente: </span>30%</p>
          <p class="text-md w-full flex items-center justify-between"><span
              class="text-primary uppercase font-semibold">Utile Esercente: </span>{{ (bar.in - bar.out) * 0.3 | money }}</p>
        </div>

        <button class="py-1 px-4 text-md uppercase font-semibold rounded-md bg-primary text-black hover:bg-secondary"
                @click="">Incasso</button>

        <div>
          <h3 class="text-white text-md uppercase font-semibold mt-2">Sfondo</h3>

          <div class="flex items-center justify-center p-3">
            <custom-image :original="selectedWallpaper.preview" class-list=""/>
          </div>

          <div class="flex items-center">
            <button v-for="(wallpaper, index) in bar.availableWallpapers" :key="index"
                    class="py-1 px-4 text-sm uppercase font-semibold"
                    :class="[selectedWallpaper.slug === wallpaper.slug ? 'cursor-not-allowed bg-gray-600 text-white' : 'cursor-pointer bg-secondary text-black', {'rounded-l-md': index === 0}, {'rounded-r-md': index === bar.availableWallpapers.length - 1} ]"
                    @click="updateWallpaper(wallpaper, index)">{{ wallpaper.slug }}
            </button>
          </div>
        </div>
      </div>

      <div class="flex-1 flex items-center justify-between gap-4 pl-10">
        <router-link v-for="(cabinet, index) in bar.cabinets" :key="index"
                     class="relative flex-1 flex flex-col gap-0 cursor-pointer"
                     :to="{name: 'GlimpseManagerBarCabinet', params: {region: $route.params.region, bar: $route.params.bar, cabinet: index}}">
          <custom-image :original="cabinet.reserved" alt="" class-list="w-full" v-if="cabinet.reserved"/>
          <custom-image :original="cabinet.img" alt="" class-list="w-full" v-else/>
          <div class="w-full px-3 py-6 bg-gray-900 shadow-md rounded-md absolute" style="bottom: -8%">
            <p class="text-lg w-full flex items-center justify-between"><span class="text-xs text-secondary uppercase font-semibold">Giocho: </span>{{
                $root.$data.games[cabinet.type][cabinet.game].name
              }}</p>
            <p class="text-lg w-full flex items-center justify-between"><span class="text-xs text-secondary uppercase font-semibold">In: </span>{{
                cabinet.in | money
              }}</p>
            <p class="text-lg w-full flex items-center justify-between"><span class="text-xs text-secondary uppercase font-semibold">Out: </span>{{
                cabinet.out | money
              }}</p>
            <p class="text-lg w-full flex items-center justify-between"><span class="text-xs text-secondary uppercase font-semibold">% Payout: </span>{{
                parseInt(cabinet.out * 100 / cabinet.in)
              }}%</p>
            <p class="text-lg w-full flex items-center justify-between"><span class="text-xs text-primary uppercase font-semibold">Cassetto: </span>{{
                cabinet.in - cabinet.out | money
              }}</p>
<!--            <p class="text-lg w-full flex items-center justify-between"><span
                class="text-xs text-secondary uppercase font-semibold">% Esercente: </span>30%</p>-->
            <p class="text-lg w-full flex items-center justify-between"><span
                class="text-xs text-primary uppercase font-semibold">Utile Esercente: </span>{{ (cabinet.in - cabinet.out) * 0.3 | money }}</p>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import SectionTitle from "@/components/SectionTitle";

export default {
  name: "Bars",

  components: {SectionTitle},

  data() {
    return {
      bar: null
    }
  },

  computed: {
    selectedWallpaper() {
      if (this.bar) {
        return this.bar.availableWallpapers.filter(wallpaper => {
          return wallpaper.selected
        })[0];
      }
    }
  },

  mounted() {
    this.bar = this.$root.$data.barsPerRegion[this.$route.params.region][this.$route.params.bar]
  },

  methods: {
    updateWallpaper(wallpaper, index) {
      this.bar.availableWallpapers.map(wallpaper => wallpaper.selected = false);
      this.bar.availableWallpapers[index].selected = true;

      this.$root.$data.barsPerRegion[this.$route.params.region][this.$route.params.bar] = this.bar;
    }
  },
}
</script>

<style scoped>

</style>
