<template>
  <div>
    <FullWidthBanner/>

    <SectionTitle title="Lotteria">
      <div class="pb-4 pt-16 px-6 shadow-xl" slot="filters">
        <div class="inline-flex items-center divide-x divide-white">
          <a href="#" class="uppercase text-xs font-semibold px-12 hover:text-primary">Lotteria</a>
          <a href="#" class="uppercase text-xs font-semibold px-12 hover:text-primary">Bingo</a>
          <a href="#" class="uppercase text-xs font-semibold px-12 hover:text-primary">Gratta e Vinci</a>
          <a href="#" class="uppercase text-xs font-semibold px-12 hover:text-primary">MillionDAY</a>
        </div>
      </div>
    </SectionTitle>

    <section class="w-full">
      <div class="px-16 mt-10">
        <h1 class="uppercase text-secondary text-shadow text-xl font-bold">In Evidenza</h1>
      </div>

      <div class="px-16 flex items-center justify-end my-3">
        <button class="text-white hover:text-primary group">
          <svg class="w-6 h-6 stroke-current" fill="none" viewBox="0 0 24 24"
               xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width={2} d="M15 19l-7-7 7-7"/>
          </svg>
        </button>

        <button class="text-white hover:text-primary group">
          <svg class="w-6 h-6z stroke-current" fill="none" viewBox="0 0 24 24"
               xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width={2} d="M9 5l7 7-7 7"/>
          </svg>
        </button>
      </div>

      <div class="px-16 flex items-center justify-between gap-32">
        <div class="relative flex-1 ">
          <div class="mb-1 py-2 px-4 w-full flex items-center justify-between rounded-t-lg bg-gradient-to-r from-primary via-blue-800 to-transparent">
            <h3 class="font-semibold text-white">Lotto</h3>

            <button>
              <svg class="w-6 h-6 stroke-current" fill="none" viewBox="0 0 24 24"
                   xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width={2}
                      d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"/>
              </svg>
            </button>
          </div>
          <a class="mt-1 w-full bg-gray-800 cursor-pointer group">
            <custom-image original="/img/lottery/lotto.jpg" class-list="w-full rounded-b-sm"/>

            <div class="absolute w-full text-center opacity-0 group-hover:opacity-100 transition duration-300"
                 style="bottom: 5%">
              <button class="uppercase bg-primary text-black text-md font-semibold rounded-full px-8 py-1">Gioca Ora
              </button>
            </div>
          </a>
        </div>
        <div class="relative flex-1 ">
          <div class="mb-1 py-2 px-4 w-full flex items-center justify-between rounded-t-lg bg-gradient-to-r from-primary via-blue-800 to-transparent">
            <h3 class="font-semibold text-white">Gratta e Vinci</h3>

            <button>
              <svg class="w-6 h-6 stroke-current" fill="none" viewBox="0 0 24 24"
                   xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width={2}
                      d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"/>
              </svg>
            </button>
          </div>
          <a class="mt-1 w-full bg-gray-800 cursor-pointer group">
            <custom-image original="/img/lottery/gratta_vinci.jpg" class-list="w-full rounded-b-sm"/>

            <div class="absolute w-full text-center opacity-0 group-hover:opacity-100 transition duration-300"
                 style="bottom: 5%">
              <button class="uppercase bg-primary text-black text-md font-semibold rounded-full px-8 py-1">Gioca Ora
              </button>
            </div>
          </a>
        </div>
        <div class="relative flex-1 ">
          <div class="mb-1 py-2 px-4 w-full flex items-center justify-between rounded-t-lg bg-gradient-to-r from-primary via-blue-800 to-transparent">
            <h3 class="font-semibold text-white">MillionDAY</h3>

            <button>
              <svg class="w-6 h-6 stroke-current" fill="none" viewBox="0 0 24 24"
                   xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width={2}
                      d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"/>
              </svg>
            </button>
          </div>
          <a class="mt-1 w-full bg-gray-800 cursor-pointer group">
            <custom-image original="/img/lottery/million_day.jpg" class-list="w-full rounded-b-sm"/>

            <div class="absolute w-full text-center opacity-0 group-hover:opacity-100 transition duration-300"
                 style="bottom: 5%">
              <button class="uppercase bg-primary text-black text-md font-semibold rounded-full px-8 py-1">Gioca Ora
              </button>
            </div>
          </a>
        </div>
      </div>
    </section>

    <section class="w-full mt-24">
      <div class="px-16 mt-10">
        <h1 class="uppercase text-secondary text-shadow text-xl font-bold">Vinti la Scorsa Settimana</h1>
      </div>

      <div class="px-16 py-8 flex items-center justify-between gap-12">
        <div class="flex-1 flex flex-col items-center py-2 px-8 rounded-sm bg-pattern-cyan">
          <h3 class="uppercase font-semibold text-xs text-shadow">Vinti Lotto</h3>
          <p class="font-semibold text-lg text-shadow tracking-widest">91.096,61€</p>
        </div>
        <div class="flex-1 flex flex-col items-center py-2 px-8 rounded-sm bg-pattern-yellow">
          <h3 class="uppercase font-semibold text-xs text-shadow">Vincite 10ELotto</h3>
          <p class="font-semibold text-lg text-shadow tracking-widest">1.074.967,00€</p>
        </div>
        <div class="flex-1 flex flex-col items-center py-2 px-8 rounded-sm bg-pattern-orange">
          <h3 class="uppercase font-semibold text-xs text-shadow">Vinti Gratta e Vinci</h3>
          <p class="font-semibold text-lg text-shadow tracking-widest">587.761,50€</p>
        </div>
        <div class="flex-1 flex flex-col items-center py-2 px-8 rounded-sm bg-pattern-red">
          <h3 class="uppercase font-semibold text-xs text-shadow">Vinti Million Day</h3>
          <p class="font-semibold text-lg text-shadow tracking-widest">21.480,00€</p>
        </div>
      </div>
    </section>

    <section class="w-full mt-24">
      <div class="px-16 mt-10">
        <h1 class="uppercase text-secondary text-shadow text-xl font-bold">I Più Giocati</h1>
      </div>

      <div class="px-16 flex items-center justify-end my-3">
        <button class="text-white hover:text-primary group">
          <svg class="w-6 h-6 stroke-current" fill="none" viewBox="0 0 24 24"
               xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width={2} d="M15 19l-7-7 7-7"/>
          </svg>
        </button>

        <button class="text-white hover:text-primary group">
          <svg class="w-6 h-6z stroke-current" fill="none" viewBox="0 0 24 24"
               xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width={2} d="M9 5l7 7-7 7"/>
          </svg>
        </button>
      </div>

      <div class="px-16 flex items-center justify-between gap-12">
        <div class="relative flex-1 ">
          <div class="mb-1 py-2 px-4 w-full flex items-center justify-between rounded-t-lg bg-gradient-to-r from-primary via-blue-800 to-transparent">
            <h3 class="font-semibold text-white">Lotto</h3>

            <button>
              <svg class="w-6 h-6 stroke-current" fill="none" viewBox="0 0 24 24"
                   xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width={2}
                      d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"/>
              </svg>
            </button>
          </div>
          <a class="mt-1 w-full bg-gray-800 cursor-pointer group">
            <custom-image original="/img/lottery/lotto.jpg" class-list="w-full rounded-b-sm"/>

            <div class="absolute w-full text-center opacity-0 group-hover:opacity-100 transition duration-300"
                 style="bottom: 5%">
              <button class="uppercase bg-primary text-black text-md font-semibold rounded-full px-8 py-1">Gioca Ora
              </button>
            </div>
          </a>
        </div>
        <div class="relative flex-1 ">
          <div class="mb-1 py-2 px-4 w-full flex items-center justify-between rounded-t-lg bg-gradient-to-r from-primary via-blue-800 to-transparent">
            <h3 class="font-semibold text-white">Gratta e Vinci</h3>

            <button>
              <svg class="w-6 h-6 stroke-current" fill="none" viewBox="0 0 24 24"
                   xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width={2}
                      d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"/>
              </svg>
            </button>
          </div>
          <a class="mt-1 w-full bg-gray-800 cursor-pointer group">
            <custom-image original="/img/lottery/gratta_vinci.jpg" class-list="w-full rounded-b-sm"/>

            <div class="absolute w-full text-center opacity-0 group-hover:opacity-100 transition duration-300"
                 style="bottom: 5%">
              <button class="uppercase bg-primary text-black text-md font-semibold rounded-full px-8 py-1">Gioca Ora
              </button>
            </div>
          </a>
        </div>
        <div class="relative flex-1 ">
          <div class="mb-1 py-2 px-4 w-full flex items-center justify-between rounded-t-lg bg-gradient-to-r from-primary via-blue-800 to-transparent">
            <h3 class="font-semibold text-white">MillionDAY</h3>

            <button>
              <svg class="w-6 h-6 stroke-current" fill="none" viewBox="0 0 24 24"
                   xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width={2}
                      d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"/>
              </svg>
            </button>
          </div>
          <a class="mt-1 w-full bg-gray-800 cursor-pointer group">
            <custom-image original="/img/lottery/million_day.jpg" class-list="w-full rounded-b-sm"/>

            <div class="absolute w-full text-center opacity-0 group-hover:opacity-100 transition duration-300"
                 style="bottom: 5%">
              <button class="uppercase bg-primary text-black text-md font-semibold rounded-full px-8 py-1">Gioca Ora
              </button>
            </div>
          </a>
        </div>
        <div class="relative flex-1 ">
          <div class="mb-1 py-2 px-4 w-full flex items-center justify-between rounded-t-lg bg-gradient-to-r from-primary via-blue-800 to-transparent">
            <h3 class="font-semibold text-white">MillionDAY</h3>

            <button>
              <svg class="w-6 h-6 stroke-current" fill="none" viewBox="0 0 24 24"
                   xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width={2}
                      d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"/>
              </svg>
            </button>
          </div>
          <a class="mt-1 w-full bg-gray-800 cursor-pointer group">
            <custom-image original="/img/lottery/million_day.jpg" class-list="w-full rounded-b-sm"/>

            <div class="absolute w-full text-center opacity-0 group-hover:opacity-100 transition duration-300"
                 style="bottom: 5%">
              <button class="uppercase bg-primary text-black text-md font-semibold rounded-full px-8 py-1">Gioca Ora
              </button>
            </div>
          </a>
        </div>
      </div>
    </section>

    <section class="w-full mt-24">
      <div class="px-16 mt-10">
        <h1 class="uppercase text-secondary text-shadow text-xl font-bold">Vincita Gratta e Vinci della Scorsa Settimana</h1>
      </div>

      <div class="px-16 py-8 flex items-center justify-between gap-12">
        <div class="flex rounded-sm">
          <custom-image original="/img/lottery/lotto.jpg" class-list="h-16"/>
          <div class="flex-1 flex flex-col items-center py-2 px-8 bg-white">
            <h3 class="uppercase font-semibold text-xs text-gray-900">Vinti Lotto</h3>
            <p class="font-semibold text-lg text-gray-900 tracking-widest">91.096,61€</p>
          </div>
        </div>
        <div class="flex rounded-sm">
          <custom-image original="/img/lottery/lotto.jpg" class-list="h-16"/>
          <div class="flex-1 flex flex-col items-center py-2 px-8 bg-white">
            <h3 class="uppercase font-semibold text-xs text-gray-900">Vinti Lotto</h3>
            <p class="font-semibold text-lg text-gray-900 tracking-widest">91.096,61€</p>
          </div>
        </div>
        <div class="flex rounded-sm">
          <custom-image original="/img/lottery/lotto.jpg" class-list="h-16"/>
          <div class="flex-1 flex flex-col items-center py-2 px-8 bg-white">
            <h3 class="uppercase font-semibold text-xs text-gray-900">Vinti Lotto</h3>
            <p class="font-semibold text-lg text-gray-900 tracking-widest">91.096,61€</p>
          </div>
        </div>
        <div class="flex rounded-sm">
          <custom-image original="/img/lottery/lotto.jpg" class-list="h-16"/>
          <div class="flex-1 flex flex-col items-center py-2 px-8 bg-white">
            <h3 class="uppercase font-semibold text-xs text-gray-900">Vinti Lotto</h3>
            <p class="font-semibold text-lg text-gray-900 tracking-widest">91.096,61€</p>
          </div>
        </div>
      </div>
    </section>

    <section class="w-full mt-24">
      <div class="px-16 mt-10">
        <h1 class="uppercase text-secondary text-shadow text-xl font-bold">Altre Lotterie</h1>
      </div>

      <div class="px-16 flex items-center justify-end my-3">
        <button class="text-white hover:text-primary group">
          <svg class="w-6 h-6 stroke-current" fill="none" viewBox="0 0 24 24"
               xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width={2} d="M15 19l-7-7 7-7"/>
          </svg>
        </button>

        <button class="text-white hover:text-primary group">
          <svg class="w-6 h-6z stroke-current" fill="none" viewBox="0 0 24 24"
               xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width={2} d="M9 5l7 7-7 7"/>
          </svg>
        </button>
      </div>

      <div class="px-16 flex items-center justify-between gap-12">
        <div class="relative flex-1 ">
          <div class="mb-1 py-2 px-4 w-full flex items-center justify-between rounded-t-lg bg-gradient-to-r from-primary via-blue-800 to-transparent">
            <h3 class="font-semibold text-white">Lotto</h3>

            <button>
              <svg class="w-6 h-6 stroke-current" fill="none" viewBox="0 0 24 24"
                   xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width={2}
                      d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"/>
              </svg>
            </button>
          </div>
          <a class="mt-1 w-full bg-gray-800 cursor-pointer group">
            <custom-image original="/img/lottery/lotto.jpg" class-list="w-full rounded-b-sm"/>

            <div class="absolute w-full text-center opacity-0 group-hover:opacity-100 transition duration-300"
                 style="bottom: 5%">
              <button class="uppercase bg-primary text-black text-md font-semibold rounded-full px-8 py-1">Gioca Ora
              </button>
            </div>
          </a>
        </div>
        <div class="relative flex-1 ">
          <div class="mb-1 py-2 px-4 w-full flex items-center justify-between rounded-t-lg bg-gradient-to-r from-primary via-blue-800 to-transparent">
            <h3 class="font-semibold text-white">Gratta e Vinci</h3>

            <button>
              <svg class="w-6 h-6 stroke-current" fill="none" viewBox="0 0 24 24"
                   xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width={2}
                      d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"/>
              </svg>
            </button>
          </div>
          <a class="mt-1 w-full bg-gray-800 cursor-pointer group">
            <custom-image original="/img/lottery/gratta_vinci.jpg" class-list="w-full rounded-b-sm"/>

            <div class="absolute w-full text-center opacity-0 group-hover:opacity-100 transition duration-300"
                 style="bottom: 5%">
              <button class="uppercase bg-primary text-black text-md font-semibold rounded-full px-8 py-1">Gioca Ora
              </button>
            </div>
          </a>
        </div>
        <div class="relative flex-1 ">
          <div class="mb-1 py-2 px-4 w-full flex items-center justify-between rounded-t-lg bg-gradient-to-r from-primary via-blue-800 to-transparent">
            <h3 class="font-semibold text-white">MillionDAY</h3>

            <button>
              <svg class="w-6 h-6 stroke-current" fill="none" viewBox="0 0 24 24"
                   xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width={2}
                      d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"/>
              </svg>
            </button>
          </div>
          <a class="mt-1 w-full bg-gray-800 cursor-pointer group">
            <custom-image original="/img/lottery/million_day.jpg" class-list="w-full rounded-b-sm"/>

            <div class="absolute w-full text-center opacity-0 group-hover:opacity-100 transition duration-300"
                 style="bottom: 5%">
              <button class="uppercase bg-primary text-black text-md font-semibold rounded-full px-8 py-1">Gioca Ora
              </button>
            </div>
          </a>
        </div>
        <div class="relative flex-1 ">
          <div class="mb-1 py-2 px-4 w-full flex items-center justify-between rounded-t-lg bg-gradient-to-r from-primary via-blue-800 to-transparent">
            <h3 class="font-semibold text-white">MillionDAY</h3>

            <button>
              <svg class="w-6 h-6 stroke-current" fill="none" viewBox="0 0 24 24"
                   xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width={2}
                      d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"/>
              </svg>
            </button>
          </div>
          <a class="mt-1 w-full bg-gray-800 cursor-pointer group">
            <custom-image original="/img/lottery/million_day.jpg" class-list="w-full rounded-b-sm"/>

            <div class="absolute w-full text-center opacity-0 group-hover:opacity-100 transition duration-300"
                 style="bottom: 5%">
              <button class="uppercase bg-primary text-black text-md font-semibold rounded-full px-8 py-1">Gioca Ora
              </button>
            </div>
          </a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SectionTitle from "@/components/SectionTitle";
import FullWidthBanner from "@/components/FullWidthBanner";

export default {
  components: {
    SectionTitle,
    FullWidthBanner
  }
}
</script>
