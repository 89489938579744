<template>
  <div class="flex-1">
    <div class="container mx-auto py-20 overflow-y-auto" style="max-height: calc(100vh*0.8)">
      <div
          class="w-full flex items-center gap-6 bg-gradient-to-r from-secondary to-red-900 text-white py-4 px-8 rounded-lg mb-10 shadow-md">
        <span class="w-32"></span>

        <div class="flex items-center justify-between flex-1">
          <h2 class="w-1/6 text-2xl uppercase font-bold text-white">Totale</h2>
          <div class="w-1/6 flex flex-col items-center gap-1">
            <p class="text-2xl font-bold text-white">{{ totals.bars_count }}</p>
            <p class="uppercase text-sm font-semibold">Bars</p>
          </div>
          <div class="w-1/6 flex flex-col items-center gap-1">
            <p class="text-2xl font-bold text-white">{{ totals.in | money }}</p>
            <p class="uppercase text-sm font-semibold">In</p>
          </div>
          <div class="w-1/6 flex flex-col items-center gap-1">
            <p class="text-2xl font-bold text-white">{{ totals.out | money }}</p>
            <p class="uppercase text-sm font-semibold">Out</p>
          </div>
          <div class="w-1/6 flex flex-col items-center gap-1">
            <p class="text-2xl font-bold text-white">{{ totals.in - totals.out | money }}</p>
            <p class="uppercase text-sm font-semibold">Cassetto</p>
          </div>
          <div class="w-1/6 flex flex-col items-center gap-1">
            <p class="text-2xl font-bold text-white">30 %</p>
            <p class="uppercase text-sm font-semibold">% Esercente</p>
          </div>
          <div class="w-1/6 flex flex-col items-center gap-1">
            <p class="text-2xl font-bold text-white">{{ (totals.in - totals.out) * 0.3 | money }}</p>
            <p class="uppercase text-sm font-semibold">Utile Esercente</p>
          </div>
        </div>
      </div>

      <div v-for="(bars, region) in $root.$data.barsPerRegion" :key="region">
        <div
            class="w-full flex items-center gap-6 bg-gradient-to-r from-primary to-blue-800 text-white py-4 px-8 rounded-lg mb-5 mt-10 shadow-md">
          <span class="w-32"></span>

          <div class="flex items-center justify-between flex-1">
            <h2 class="w-1/6 text-2xl uppercase font-bold text-white">{{ region }}</h2>
            <div class="w-1/6 flex flex-col items-center gap-1">
              <p class="text-2xl font-bold text-white">{{ regionBarsCount(bars) }}</p>
              <p class="uppercase text-sm font-semibold">Bars</p>
            </div>
            <div class="w-1/6 flex flex-col items-center gap-1">
              <p class="text-2xl font-bold text-white">{{ regionTotalIn(bars) | money }}</p>
              <p class="uppercase text-sm font-semibold">In</p>
            </div>
            <div class="w-1/6 flex flex-col items-center gap-1">
              <p class="text-2xl font-bold text-white">{{ regionTotalOut(bars) | money }}</p>
              <p class="uppercase text-sm font-semibold">Out</p>
            </div>
            <div class="w-1/6 flex flex-col items-center gap-1">
              <p class="text-2xl font-bold text-white">{{ regionTotalIn(bars) - regionTotalOut(bars) | money }}</p>
              <p class="uppercase text-sm font-semibold">Cassetto</p>
            </div>
            <div class="w-1/6 flex flex-col items-center gap-1">
              <p class="text-2xl font-bold text-white">30 %</p>
              <p class="uppercase text-sm font-semibold">% Esercente</p>
            </div>
            <div class="w-1/6 flex flex-col items-center gap-1">
              <p class="text-2xl font-bold text-white">{{ (regionTotalIn(bars) - regionTotalOut(bars)) * 0.3 | money }}</p>
              <p class="uppercase text-sm font-semibold">Utile Esercente</p>
            </div>
          </div>
        </div>

        <router-link
            class="w-full flex items-center gap-6 bg-black bg-opacity-80 text-white py-4 px-8 rounded-lg cursor-pointer hover:bg-secondary hover:bg-opacity-70 mb-3 shadow-md"
            v-for="(bar, index) in bars" :key="index"
            :to="{name: 'GlimpseManagerBar', params: {region: region, bar: index}}">
          <img class="w-32 h-32 rounded-full" :src="bar.image" :alt="bar.name"/>

          <div class="flex items-center justify-between flex-1">
            <h2 class="w-1/6 text-2xl uppercase font-bold">{{ bar.name }}</h2>
            <div class="w-1/6 flex flex-col items-center gap-1">
              <p class="text-2xl font-bold">{{ bar.cabinets.length }}</p>
              <p class="uppercase text-sm font-semibold">Cabinets</p>
            </div>
            <div class="w-1/6 flex flex-col items-center gap-1">
              <p class="text-2xl font-bold">{{ bar.in | money }}</p>
              <p class="uppercase text-sm font-semibold">In</p>
            </div>
            <div class="w-1/6 flex flex-col items-center gap-1">
              <p class="text-2xl font-bold">{{ bar.out | money }}</p>
              <p class="uppercase text-sm font-semibold">Out</p>
            </div>
            <div class="w-1/6 flex flex-col items-center gap-1">
              <p class="text-2xl font-bold">{{ bar.in - bar.out | money }}</p>
              <p class="uppercase text-sm font-semibold">Cassetto</p>
            </div>
            <div class="w-1/6 flex flex-col items-center gap-1">
              <p class="text-2xl font-bold text-white">30 %</p>
              <p class="uppercase text-sm font-semibold">% Esercente</p>
            </div>
            <div class="w-1/6 flex flex-col items-center gap-1">
              <p class="text-2xl font-bold text-white">{{ (bar.in - bar.out) * 0.3 | money }}</p>
              <p class="uppercase text-sm font-semibold">Utile Esercente</p>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>

</template>

<script>
import _ from 'lodash'

export default {
  name: "Bars",

  computed: {
    totals() {
      let inValue = 0
      let outValue = 0
      let barsCount = 0;

      Object.values(this.$root.$data.barsPerRegion).forEach(bars => {
        inValue += this.regionTotalIn(bars);
        outValue += this.regionTotalOut(bars);
        barsCount += this.regionBarsCount(bars);
      })

      return {
        in: inValue,
        out: outValue,
        bars_count: barsCount
      }
    }
  },

  methods: {
    regionTotalIn(bars) {
      return _.sumBy(bars, bar => {
        return bar.in
      });
    },
    regionTotalOut(bars) {
      return _.sumBy(bars, bar => {
        return bar.out
      });
    },
    regionBarsCount(bars) {
      return bars.length
    },
  },
}
</script>

<style scoped>

</style>
