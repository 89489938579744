<template>
  <div v-if="reset">Reset Done!!!</div>
</template>

<script>
export default {
  name: "Reset",

  data() {
    return {
      reset: false
    }
  },

  mounted() {
    localStorage.clear();
    this.reset = true;
  }
}
</script>

<style scoped>

</style>
