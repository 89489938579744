<template>
  <div class="flex-1" v-if="bar">
    <SectionTitle :title="bar.name">
      <div class="pb-4 pt-8 px-6 shadow-xl" slot="filters">
        <div class="inline-flex items-center divide-x divide-white">
          <router-link :to="{name: 'GlimpseManagerBars'}" class="uppercase text-xs font-semibold px-12 hover:text-primary" v-if="!$root.$data.barSigned">Dashboard</router-link>
          <router-link :to="{name: 'GlimpseManagerBar', params: {name: bar.name}}" class="uppercase text-xs font-semibold px-12 hover:text-primary">{{ bar.name }}</router-link>
        </div>
      </div>
    </SectionTitle>

    <div class="mt-12 container mx-auto flex justify-between divide-x-2 divide-gray-600">
      <div class="flex flex-col items-center justify-center gap-2 pr-10 w-1/5">
        <custom-image class-list="w-full" :original="cabinet.reserved" v-if="cabinet.reserved"/>
        <custom-image class-list="w-full" :original="cabinet.img" v-else />

        <div class="flex items-center">
          <button class="py-1 px-4 text-sm uppercase font-semibold rounded-l-md"
                  :class="[cabinet.type === 'vlt' ? 'cursor-not-allowed bg-gray-600 text-white' : 'cursor-pointer bg-secondary text-black']"
                  @click="cabinet.type = 'vlt'">VLT</button>
          <button class="py-1 px-4 text-sm uppercase font-semibold rounded-r-md"
                  :class="[cabinet.type === 'awp' ? 'cursor-not-allowed bg-gray-600 text-white' : 'cursor-pointer bg-secondary text-black']"
                  @click="cabinet.type = 'awp'">AWP</button>
        </div>
<!--        <div class="flex items-center">
          <button class="py-1 px-4 text-sm uppercase font-semibold rounded-l-md"
                  :class="[cabinet.reserved ? 'cursor-not-allowed bg-gray-600 text-white' : 'cursor-pointer bg-secondary text-black']"
                  @click="reserveCabinet">Riservato</button>
          <button class="py-1 px-4 text-sm uppercase font-semibold rounded-r-md"
                  :class="[!cabinet.reserved ? 'cursor-not-allowed bg-gray-600 text-white' : 'cursor-pointer bg-secondary text-black']"
                  @click="removeCabinetReservation">Non Riservato</button>
        </div>-->
      </div>

      <div class="flex-1 gap-20 pl-20">
        <div
            class="w-full flex items-center gap-6 bg-black bg-opacity-80 text-white py-4 px-8 rounded-lg mb-10 shadow-md">
          <span class="w-32"></span>

          <div class="flex items-center justify-between flex-1">
            <h2 class="w-1/4 text-2xl uppercase font-bold text-primary">{{ $root.$data.games[cabinet.type][cabinet.game].name }}</h2>
            <div class="w-1/4 flex flex-col items-center gap-1">
              <p class="text-3xl font-bold text-primary">{{ cabinet.in | money }}</p>
              <p class="uppercase text-sm font-semibold">In</p>
            </div>
            <div class="w-1/4 flex flex-col items-center gap-1">
              <p class="text-3xl font-bold text-primary">{{ cabinet.out | money }}</p>
              <p class="uppercase text-sm font-semibold">Out</p>
            </div>
            <div class="w-1/4 flex flex-col items-center gap-1">
              <p class="text-3xl font-bold text-primary">{{ cabinet.in - cabinet.out | money }}</p>
              <p class="uppercase text-sm font-semibold">Incasso</p>
            </div>
          </div>
        </div>

        <div class="w-full flex flex-wrap items-center justify-between gap-8">
          <img v-for="(game, index) in $root.$data.games[cabinet.type]" :key="index"
               class="w-1/4 rounded-md shadow-md cursor-pointer"
               :class="{'overlay': game.name === $root.$data.games[cabinet.type][cabinet.game].name }"
               :src="game.image" alt=""
               @click="cabinet.game = index"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionTitle from "@/components/SectionTitle";

export default {
  name: "BarCabinet",

  components: {SectionTitle},

  data() {
    return {
      bar: null,
      cabinet: null,
    }
  },

  watch: {
      cabinet: {
        deep: true,
        handler(newCabinet, oldCabinet) {
          if(oldCabinet) {
            if(newCabinet.type === 'vlt') {
              this.cabinet.game = 0
              this.cabinet.img = '/img/glimpse/cabinets/VLT/vlt_base.png'
            } else {
              this.cabinet.img = '/img/glimpse/cabinets/glamour/glamour_' + this.$root.$data.games[newCabinet.type][newCabinet.game].slug + '.png'
            }

            if(newCabinet.reserved) {
              if(newCabinet.type === 'vlt') {
                this.cabinet.reserved = '/img/glimpse/cabinets/VLT/vlt_riservato.png';
              } else {
                this.cabinet.reserved = '/img/glimpse/cabinets/glamour/glamour_reservato.png'
              }
            }

            this.bar.cabinets[this.$route.params.cabinet] = this.cabinet;
            this.$root.$data.barsPerRegion[this.$route.params.region][this.$route.params.bar] = this.bar;
          }

        }
      }
  },

  mounted() {
    this.bar = this.$root.$data.barsPerRegion[this.$route.params.region][this.$route.params.bar]
    this.cabinet = this.bar.cabinets[this.$route.params.cabinet]
  },

  methods: {
    reserveCabinet() {
      if(this.cabinet.type === 'vlt') {
        this.cabinet.reserved = '/img/glimpse/cabinets/VLT/vlt_riservato.png';
      } else {
        this.cabinet.reserved = '/img/glimpse/cabinets/glamour/glamour_reservato.png'
      }
      this.cabinet.freeToPlay = false;
      this.cabinet.playing = false;

      this.$forceUpdate();
    },
    removeCabinetReservation() {
      delete this.cabinet.reserved;
      this.cabinet.freeToPlay = true;

      this.$forceUpdate();
    }
  },

  filters: {
    money(value) {
      if (!value) {
        return ''
      }
      return new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(value)
    }
  },
}
</script>

<style scoped>
  .overlay {
    filter: grayscale(100%);
    cursor: not-allowed;
  }
</style>
